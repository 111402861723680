import React from "react";
import { DiscographyShowPageProps } from "../lib";
import PageLayout from "./PageLayout";

export default ({ title, artwork, songs }: DiscographyShowPageProps) => {
  return (
    <PageLayout>
      <article>
        <h1>{title}</h1>
        <div style={{ width: 300 }}>{artwork}</div>
        <h2>Tracks:</h2>
        <ol>
          {songs.map(({ slug, title }) => (
            <li key={slug}>
              <a href={slug}>{title}</a>
            </li>
          ))}
        </ol>
      </article>
    </PageLayout>
  );
};
